import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="bg-white shadow-md h-16 flex items-center">
      <div className="container mx-auto px-4">
        <Link to="/">
          <h1 className="font-logo text-xl font-semibold text-left text-primary">
            MissedCute
          </h1>
        </Link>
      </div>
    </header>
  );
};

export default Header;
