import React from "react";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="privacy-policy pt-10 pb-10 pl-20 pr-20">
        <h1 className="text-xl font-bold mb-4">Privacy Policy</h1>

        <p className="mb-2">
          <strong>Last Updated:</strong> 2023-12-24
        </p>

        <p>
          Welcome to MissedCute ("we", "us", "our"). We respect your privacy and
          are committed to protecting your personal information. This privacy
          policy outlines our practices concerning the collection, use, and
          disclosure of your data when you use our app and sign up for our
          waitlist, and tells you about your privacy rights and how the law
          protects you.
        </p>

        <h2 className="text-lg font-semibold mt-4 mb-2">
          1. Information We Collect
        </h2>
        <p>
          When you sign up for our waitlist or subscribe to our blog, we collect
          the following information:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Email Address</strong>: To communicate with you about our
            app's launch, updates, and to send you blog content.
          </li>
        </ul>

        <h2 className="text-lg font-semibold mt-4 mb-2">
          2. How We Use Your Information
        </h2>
        <p>We use the information we collect in various ways, including to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Contact you about our app's launch.</li>
          <li>
            Send you informational and marketing content, such as newsletters,
            marketing or promotional materials, and other information that may
            be of interest to you.
          </li>
          <li>Improve and expand our services.</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the app, and for marketing
            and promotional purposes.
          </li>
        </ul>

        <h2 className="text-lg font-semibold mt-4 mb-2">
          3. Sharing Your Personal Information
        </h2>
        <p>
          We do not sell your personal information. We may share your
          information with third-party service providers who perform services on
          our behalf, such as email marketing. These third-party service
          providers are not permitted to use your personal information for any
          other purposes, and they are required to protect the confidentiality
          and security of the information.
        </p>

        <h2 className="text-lg font-semibold mt-4 mb-2">
          4. Changes to Our Privacy Policy
        </h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
