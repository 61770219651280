import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAIHNYjh_97kNVLknN_D55dN_QkY6awiFY",
  authDomain: "missedcute-lp.firebaseapp.com",
  projectId: "missedcute-lp",
  storageBucket: "missedcute-lp.appspot.com",
  messagingSenderId: "94662289510",
  appId: "1:94662289510:web:e3bdc4de1592b3885365a7",
  measurementId: "G-7HNHQZWN25",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
