import { IconButton } from "@mui/material";
import backgroundImage from "../assets/bg_v0_4x.jpg";
import CallToAction from "./CallToAction";
import Header from "./Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./LandingPageHeaderLandscape.css";

function LandingPageHeaderLandscape() {
  const scrollToDetails = () => {
    const detailsElement = document.getElementById("details-container");
    if (detailsElement) {
      detailsElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Header />
      <div
        className="w-full flex flex-col items-center justify-center bg-cover bg-center p-5"
        style={{
          height: "calc(100vh - 4rem)",
          minHeight: "380px",
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.50), rgba(255, 255, 255, 0.50)), url(${backgroundImage})`,
        }}
      >
        <CallToAction />
        <IconButton onClick={scrollToDetails}>
          <ExpandMoreIcon className="LandingPageHeaderLandscape__more text-primary" />
        </IconButton>
      </div>
    </div>
  );
}

export default LandingPageHeaderLandscape;
