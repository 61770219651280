import React, { useState, ChangeEvent, FormEvent } from "react";
import "./EmailSignup.css";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";

const EmailSignup: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const emailQuery = query(
      collection(db, "waitlist"),
      where("email", "==", email),
      limit(1)
    );
    const querySnapshot = await getDocs(emailQuery);

    if (!querySnapshot.empty) {
      setSuccessfulEmailSaved();
      return;
    }

    try {
      await addDoc(collection(db, "waitlist"), {
        email: email,
        created: serverTimestamp(),
      });
      setSuccessfulEmailSaved();
    } catch (error) {
      console.error("Error adding email to Firestore:", error);
    }

    function setSuccessfulEmailSaved() {
      setEmail("");
      setToastMessage("Thank you for subscribing!");
      setTimeout(() => setToastMessage(""), 5000); // Clear toast message after 5 seconds
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  return (
    <div>
      {toastMessage && <div className="toast-message">{toastMessage}</div>}
      <form className="email-signup-form pt-[2vw]" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleChange}
          className="email-input text-primary border-primary"
          id="waitlist-email-input"
        />

        <button type="submit" className="signup-button uppercase bg-primary">
          Join Waitlist
        </button>
      </form>
    </div>
  );
};

export default EmailSignup;
