import { Route, Routes } from "react-router-dom";
import LandingPage from "./Components/LandingPage";
import PrivacyPolicy from "./Components/PrivacyPolicy";

function App() {
  return (
    <div className="bg-white">
      <Routes>
        <Route path="/privacy" element={PrivacyPolicy()} />
        <Route path="/" element={LandingPage()} />
      </Routes>
    </div>
  );
}

export default App;
