import React from "react";
import Card from "./Card";
import TimelineItem from "./TimelineItem";
import "./Timeline.css";

const Timeline: React.FC = () => {
  return (
    <div className="timeline mx-auto p-8 bg-[#EDF2F8]">
      <TimelineItem side="left" imgSrc="/imgs/single-chairlift.png">
        <Card
          title="Spend time doing things you love in the real world"
          description="Instead of swiping through potential matches at home, venture out and experience all that life has to offer."
        />
      </TimelineItem>

      <TimelineItem side="right" imgSrc="/imgs/checkin-mountain.png">
        <Card
          title="Share your activities on MissedCute"
          description="Match with compatible individuals who were also at the same places and events. Shared experience could be the foundation of a new connection."
        />
      </TimelineItem>

      <TimelineItem side="left" imgSrc="/imgs/double-chairlift.png">
        <Card
          title="Build meaningful connections with people with shared interests"
          description="We believe genuine connections happen when people bond over shared experiences. Start a conversation about your passions, find common activities, and step back into the real world where connections flourish."
        />
      </TimelineItem>
    </div>
  );
};

export default Timeline;
