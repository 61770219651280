import React from "react";

interface CardProps {
  title: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ title, description }) => {
  return (
    <div className="card bg-white flex flex-row text-left">
      <div className="flex flex-col justify-center p-4">
        <h3 className="font-heading text-lg font-semibold mb-2">{title}</h3>
        <p
          className="font-body"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </div>
    </div>
  );
};

export default Card;
