import React from "react";
import LandingPageHeaderLandscape from "./LandingPageHeaderLandscape";
import Timeline from "./Timeline";
import { Link } from "react-router-dom";
import "./LandingPage.css";

const LandingPage = () => {
  const scrollAndFocusWaitlistEmail = () => {
    console.log("clicked");
    const targetElement = document.getElementById("waitlist-email-input");
    if (targetElement) {
      const elementRect = targetElement.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle =
        absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;

      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });

      // Optionally, set focus if the element can receive focus
      targetElement.focus({ preventScroll: true });
    }
  };

  return (
    <div className="min-h-screen flex flex-col text-terciary bg-opacity-10">
      <main className="text-center">
        <LandingPageHeaderLandscape />
        <div id="details-container">
          <div className="pt-10 pb-10 pl-5 pr-5 text-base">
            <h1 className="single-line-header font-heading">
              Not your typical dating app
            </h1>
            <h3 className="subheader-line text-base font-body text-terciary pt-5 ">
              90% of people would prefer to meet their partner in person,
              <sup>*</sup> but it's hard to make it happen. What if there's a
              new way?
            </h3>
          </div>
          <Timeline />
        </div>
        <p className="text-xs text-gray-500 pt-5 pb-5">
          <sup>*</sup> Based on a survey conducted in 2023 of 130 people
        </p>
        <div className="bg-secondary p-10">
          <h2 className="single-line-header font-heading pb-10">
            Have we piqued your interest yet?
          </h2>
          <button
            className="signup-button uppercase bg-primary"
            onClick={scrollAndFocusWaitlistEmail}
          >
            Join Waitlist
          </button>
        </div>
      </main>
      <footer className="container mx-auto p-6 flex flex-col md:flex-row items-center justify-between">
        <div className="flex -mx-6">
          <p className="mx-4 hover:opacity:80 duration-150">
            @ 2023 MissedCute. All Rights Reserved.
          </p>
          <Link to="privacy">
            <p className="mx-4 underline hover:opacity:80 duration-150">
              Privacy Policy
            </p>
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
