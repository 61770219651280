import React from "react";
import EmailSignup from "./EmailSignup";
import "./CallToAction.css";

function CallToAction() {
  return (
    <div>
      <h1 className="single-line-hero font-heading text-primary mb-[1vh] font-bold drop-shadow">
        Where your passions meet their match
      </h1>
      <p className="single-line-world text-primary font-body font-bold drop-shadow mb-[1vh]">
        Imagine a dating world where your interests lead the way.
      </p>
      <p className="single-line-cta text-primary font-body font-bold drop-shadow mb-[1vh]">
        How would your ideal love story kick off?
      </p>
      <EmailSignup />
    </div>
  );
}

export default CallToAction;
